body {
    margin: 0;
    font-family:'Ubuntu';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.large-icon{
    font-size: 80px;
}

.inDelivery{
    color: #1324df;
}

.outer-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente el contenido */
    align-items: center; /* Centra verticalmente el contenido */
}

.text-white-gray{
    color: white;
    /* text-shadow: -1px -1px 0 #154830, -1px 1px 0 #154830, 1px -1px 0 #154830, 1px 1px 0 #154830; */
}

.light-black-background{
    background-color:rgb(22, 24, 24, 0.6);
    height: 100%;
    width: 100%;
}

.height80{
    height: 80vh;
}

.pointer{
    cursor: pointer;
}

.background-crop{
    background-image: url("../images/crop.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.background-trees{
    background-image: url("../images/plantio.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.background-inner-trees{
    /* background-color: rgba(21, 89, 56, 0.85); */
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
}

.NewsSection{
    background-color: #155938;
    height: 80vh;
}

.background-nav{
    background-color: #155938;
}

.antiquewhite-text{
    color: antiquewhite
}

.grey-green-bg {
    /* background-color: rgb(193, 230, 197); */
    background-color: #d9f6e7;
}

.light-green-bg {
    background-color: white;
}

.card-link {
    color: green;
}

a {
    color: green;
}

a:hover {
    color: rgb(77, 141, 77);
}

.card-link:hover {
    color: green;
}

@media (max-width: 800px) {
    .Layout {
        padding-top: 13rem;
        padding-bottom: 10rem;
    }
}

@media (min-width: 200px) {
    .Layout {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.LayoutLeft {
    padding-left: 20%;
}

.carouselDimensions {
    width: 250px;
    height: 250px;
    margin: auto;
    margin-top: 13%;
}

.green {
    color: #1E935A;
}

.form-check-input:checked{
    background-color: #1E935A !important;
    border: 0;
}

.form-check-input:focus, .label::after, label.form-check-label:focus, .form-check-input::after, .form-check-input:not(:disabled):not(.disabled):active:focus {
    color: black;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem #1E935A !important;
}

.nav.nav-tabs .nav-link.active {
    background-color: #1E935A;
    color: white;
}

.nav.nav-tabs .nav-link{
    color: #1E935A;
}

.gm-style-mtc {
  display: none;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.productScroll{
    max-height: 20vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.bgTerraViva{
    background-color: #249c6c;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #1b925a !important;
    border-color: #1b925a;
}

.page-item .page-link {
    z-index: 3;
    color: black !important;
}

.bg-image{
    background-image: url("./../images/invernadero.jpg");
    background-size: 100%;
}

.paddingLettuce{
    padding-top: 90%;
    padding-bottom: 5%;
}

.content-container {
  max-width: 600px;    /* Limita el contenedor a 600px */
  overflow: hidden;    /* Elimina el desbordamiento */
  text-align: justify; /* Justifica el texto */
}

.content-container img {
  max-width: 100%;    /* Asegura que la imagen no se salga del contenedor */
  height: auto;       /* Mantiene la proporción de la imagen */
}

.content-container p {
  overflow-wrap: break-word;  /* Asegura que el texto no se desborde */
}