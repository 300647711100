body {
    margin: 0;
    font-family:'Ubuntu';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.home-footer{
    padding-bottom: 20%;
}

.home-blue-bg{
    background-color: #2d3949;
}

.blur-container {
    /* Apply blur effect */
    filter: blur(2px);
}

.containerNav {
    padding-left: 0% !important;
    padding-right: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.nav-text{
    color: white;
}

.navbar .nav-link.active{
    background-color: #1E935A;
    color: antiquewhite !important;
    border-radius: 10px;
}

.AcceptButton {
    background-color: #7DB300;
    border-color: #7DB300;
}

.AcceptButton:hover {
    background-color: #9bd80e;
    border-color: #9bd80e;
}

.AcceptButton:active {
    background-color: #9bd80e!important;
    border-color: #9bd80e!important;
}

.CancelButton {
    color: #1E935A;
    background-color: #ffffff;
    border-color: #1E935A;
}

.CancelButton:hover {
    color: #1E935A;
    background-color: #f0f0f0;
    border-color: #b1b0b0;
}

.CancelButton:active {
    background-color: #1E935A!important;
    border-color: #b1b0b0!important;
}

.BuyButton {
    color: #ffffff;
    background-color: #1E935A;
    border-color: #1E935A;
}

.BuyButton:hover {
    color: #ffffff;
    background-color: #7DB300;
    border-color: #7DB300;
}

.BuyButton:active {
    background-color: #1E935A!important;
    border-color: #1E935A!important;
}

.Brand{
    width: 7%;
    height: auto;
}

.PrimaryBtn {
    color: #ffffff;
    background-color: #1E935A;
    border-color: #1E935A;
}

.Pending {
    color: #ffffff;
    background-color: #62574e;
    border-color: #62574e;
}

.PrimaryBtn:hover {
    color: #ffffff;
    background-color: #1c8150;
    border-color: #1c8150;
}

.PrimaryBtn:active {
    background-color: #1E935A!important;
    border-color: #1E935A!important;
}

.PrimaryBtn:disabled {
    color: #ffffff;
    background-color: #62574e;
    border-color: #62574e;
}

.SecondaryBtn {
    color: #1E935A;
    background-color: #ffffff;
    border-color: #1E935A;
}

.SecondaryBtn:hover {
    color: #ffffff;
    background-color: #1E935A;
    border-color: #1E935A;
}

.SecondaryBtn:active {
    color: #ffffff;
    background-color: #1E935A!important;
    border-color: #1E935A!important;
}

.CarouselImage {
    margin-left: 15%;
}

.AdjustText {
    font-size: calc(7px + .7vw);
    overflow-wrap: break-word;
}

.CarouselBg {
    background-color: white;
}

.CarouselTitle {
    color: #263d4f;
}

.right.carousel-control, .left.carousel-control {
    visibility:hidden;
}

@media (max-width: 800px) {
    .ImageUp {
        width: 20vw;
        position: absolute;
        top: 0px; 
        left: 0px;
        z-index: 1;
    }

    .ImageDown {
        width: 40vw;
    }
}

@media (min-width: 800px) { 
    .ImageUp {
        width: 8vw;
        position: absolute;
        top: 0px; 
        left: 0px;
        z-index: 1;
    }

    .ImageDown {
        width: calc(18vw);
    }

}

.linkAdmin {
    margin-right: 20px;
   
}
.linkAdmin:hover {
    color: #7DB300;
}

.linkHome {
    margin-right:20px;
    color:#faebd7;
} 

.linkHome:hover {
    color: rgb(3, 5, 3);
}

.linkAboutUs {
    margin-right: 20px;
}

.linkAboutUs:hover {
    color: #7DB300;
}

.colorNav{
    background-color: #ffffff;
}

.justify {
    text-align: justify;
}

.adminDashboard:hover {
    background-color: #f7f6f6;
    cursor: pointer;
}

.card-img-top {
    width: 100%;
    height: 20vw;
    object-fit: cover;
}

.card-img-top-cat {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.activeBtn{
    background-color: #999;
}

ion-icon {
    font-size: 20px;
}